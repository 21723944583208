import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DH } from '../assets/DubHacksLogo.svg';
import Menu from '../assets/mobilehamburgermenu.svg';
import Exit from '../assets/dropexit.svg';
import { INTERNAL_LINKS } from '../utils/links';

const MobileNavSection = styled.div`
  width: 100%;
  @media (min-width: 767px) {
    display: none;
  }
`;

const MobileNavContainer = styled.div`
  position: static;
  z-index: 999;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
`;

const HamburgerMenu = styled.div.attrs((props: { backgroundURL: string }) => props)`
  left: 3px;
  position: absolute;
  z-index: 10000;
  display: block;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  padding: 0;
  background-color: transparent;
  background-image: url(${props => props.backgroundURL});
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  @media (min-width: 479px) {
    position: absolute;
    width: 50px;
    height: 50px;
    margin-top: 0;
    padding: 0;
  }
`;

const Drop = styled.div`
  scroll: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding-right: 0;
  padding-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f63690;
`;

const DropContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
`;

const DropLink = styled.a`
  display: block;
  width: auto;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: 'Work Sans', sans-serif;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
`;

export const MobileNav = () => {
  const [dropOpen, setDropOpen] = useState<boolean>(false);
  return (
    <MobileNavSection>
      <MobileNavContainer>
        {dropOpen && (
          <Drop>
            <DropContainer>
              <DropLink href={INTERNAL_LINKS.HOME}>Home</DropLink>
              <DropLink href={INTERNAL_LINKS.ABOUT}>About Us</DropLink>
              <DropLink href={INTERNAL_LINKS.JOIN}>Join Us</DropLink>
            </DropContainer>
          </Drop>
        )}
        <HamburgerMenu onClick={() => setDropOpen(!dropOpen)} backgroundURL={dropOpen ? Exit : Menu} />
        <a href={INTERNAL_LINKS.HOME} style={{ height: '30px' }}>
          <DH style={{ width: '150px' }}></DH>
        </a>
      </MobileNavContainer>
    </MobileNavSection>
  );
};
