import React from 'react';
import styled from 'styled-components';
import david from '../assets/teampics/david.jpg';
import ryan from '../assets/teampics/ryan.jpg';
import aanika from '../assets/teampics/aanika.jpg';
import alison from '../assets/teampics/alison.jpg';
import anas from '../assets/teampics/anas.jpg';
import andrew from '../assets/teampics/andrew.jpg';
import aryan from '../assets/teampics/aryan.jpg';
import ayush from '../assets/teampics/ayush.jpg';
import emily from '../assets/teampics/emily.jpg';
import erika from '../assets/teampics/erika.jpg';
import erikaNi from '../assets/teampics/erikaNi.jpg';
import grace from '../assets/teampics/grace.jpg';
import martin from '../assets/teampics/martin.jpg';
import meiyao from '../assets/teampics/meiyao.jpg';
import michael from '../assets/teampics/michael.jpg';
import mira from '../assets/teampics/mira.jpg';
import niyati from '../assets/teampics/niyati.jpg';
import nolan from '../assets/teampics/nolan.jpg';
import oorjit from '../assets/teampics/oorjit.jpg';
import ria from '../assets/teampics/ria.jpg';
import sabrina from '../assets/teampics/sabrina.jpg';
import stithi from '../assets/teampics/stithi.jpg';
import yuna from '../assets/teampics/yuna.jpg';
const names = [
  ['Andrew', 'Nakamoto', andrew],
  ['Sabrina', 'Chan', sabrina],
  ['David', 'Song', david],
  ['Ryan', 'Hsu', ryan],
  ['Anas', 'Slassi', anas],
  ['Aanika', 'Nakra', aanika],
  ['Alison', 'Tea', alison],
  ['Ayush', 'Kulkarni', ayush],
  ['Aryan', 'Mahindra', aryan],
  ['Emily', 'Hao', emily],
  ['Erika', 'Ito', erika],
  ['Erika', 'Ni', erikaNi],
  ['Grace', 'Yim', grace],
  ['Martin', 'Johnson', martin],
  ['Meiyao', 'Li', meiyao],
  ['Michael', 'Gu', michael],
  ['Mira', 'Lee', mira],
  ['Niyati', 'Trivedi', niyati],
  ['Nolan', 'Kim', nolan],
  ['Oorjit', 'Chowdhary', oorjit],
  ['Ria', 'Patil', ria],
  ['Stithi', 'Patnaik', stithi],
  ['Yuna', 'Kim', yuna],
];

const TeamSection = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const TeamContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  @media (max-width: 991 px) {
    max-width: 728px;
  }
  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (max-width: 479px) {
    max-width: none;
  }
`;

const TeamTitleContainer = styled.div`
  margin-bottom: 30px;
  padding: 10px;
  // font-family: 'Work Sans',sans-serif;
  color: #434343;
  font-size: 35px;
  line-height: 35px;
  font-weight: 700;
  text-align: center;
`;

const TeamGrid = styled.div`
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: ;
  @media (max-width: 767px) {
    margin-right: 20px;
    margin-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-flow: row;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  @media (max-width: 479px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const HeadshotContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
`;
const HeadshotImage = styled.img`
  border-radius: 100px;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const NameContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #333;
`;

export const OurTeam = () => {
  return (
    <TeamSection id="OurTeam">
      <TeamContainer>
        <TeamTitleContainer>Our Team</TeamTitleContainer>
        <TeamGrid>
          {names.map(data => {
            const first = data[0];
            const last = data[1];
            const pic = data[2];
            return <Headshot imageSource={pic} first={first} last={last} />;
          })}
        </TeamGrid>
      </TeamContainer>
    </TeamSection>
  );
};

const Headshot = ({ imageSource, first, last }: { imageSource: string; first: string; last: string }) => {
  return (
    <HeadshotContainer>
      <HeadshotImage
        src={imageSource}
        loading="eager"
        sizes="(max-width: 479px) 36vw, (max-width: 767px) 26vw, (max-width: 991px) 198.65625px, 100.5625px"
      ></HeadshotImage>
      <NameContainer>
        {first}
        <br />
        {last}
      </NameContainer>
    </HeadshotContainer>
  );
};
