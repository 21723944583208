import { Vector } from 'matter-js';
interface bodiesInterface {
  [shape: string]: Vector[];
}

export const bodies: bodiesInterface = {
  shape1: [
    { x: 206, y: 246 },
    { x: 203, y: 286 },
    { x: 196, y: 325 },
    { x: 184, y: 361 },
    { x: 170, y: 392 },
    { x: 154, y: 419 },
    { x: 137, y: 440 },
    { x: 120, y: 456 },
    { x: 104, y: 468 },
    { x: 89, y: 477 },
    { x: 75, y: 484 },
    { x: 61, y: 488 },
    { x: 47, y: 490 },
    { x: 38, y: 491 },
    { x: 12, y: 487 },
    { x: 4, y: 483 },
    { x: 0, y: 478 },
    { x: 0, y: 471 },
    { x: 1, y: 463 },
    { x: 5, y: 454 },
    { x: 12, y: 443 },
    { x: 25, y: 423 },
    { x: 65, y: 363 },
    { x: 90, y: 313 },
    { x: 100, y: 280 },
    { x: 104, y: 252 },
    { x: 104, y: 246 },
    { x: 99, y: 207 },
    { x: 91, y: 178 },
    { x: 76, y: 145 },
    { x: 54, y: 107 },
    { x: 20, y: 55 },
    { x: 0, y: 16 },
    { x: 0, y: 10 },
    { x: 2, y: 5 },
    { x: 8, y: 2 },
    { x: 17, y: 0 },
    { x: 24, y: 0 },
    { x: 50, y: 3 },
    { x: 64, y: 7 },
    { x: 79, y: 14 },
    { x: 95, y: 23 },
    { x: 112, y: 36 },
    { x: 130, y: 53 },
    { x: 148, y: 74 },
    { x: 166, y: 100 },
    { x: 181, y: 131 },
    { x: 194, y: 165 },
    { x: 202, y: 201 },
    { x: 205, y: 239 },
    { x: 206, y: 246 },
  ],
  shape2: [
    { x: 157, y: 0 },
    { x: 185, y: 0 },
    { x: 236, y: 0 },
    { x: 280, y: 0 },
    { x: 321, y: 0 },
    { x: 360, y: 0 },
    { x: 381, y: 0 },
    { x: 381, y: 18 },
    { x: 381, y: 65 },
    { x: 378, y: 85 },
    { x: 374, y: 102 },
    { x: 368, y: 117 },
    { x: 360, y: 130 },
    { x: 351, y: 142 },
    { x: 340, y: 153 },
    { x: 326, y: 164 },
    { x: 308, y: 173 },
    { x: 284, y: 182 },
    { x: 248, y: 189 },
    { x: 199, y: 192 },
    { x: 172, y: 192 },
    { x: 130, y: 192 },
    { x: 98, y: 192 },
    { x: 66, y: 192 },
    { x: 26, y: 192 },
    { x: 0, y: 192 },
    { x: 0, y: 174 },
    { x: 2, y: 129 },
    { x: 6, y: 108 },
    { x: 12, y: 89 },
    { x: 18, y: 73 },
    { x: 27, y: 59 },
    { x: 36, y: 47 },
    { x: 47, y: 35 },
    { x: 61, y: 25 },
    { x: 77, y: 16 },
    { x: 97, y: 9 },
    { x: 123, y: 3 },
    { x: 157, y: 0 },
  ],
  shape3: [
    { x: 251, y: 269 },
    { x: 281, y: 257 },
    { x: 300, y: 246 },
    { x: 318, y: 233 },
    { x: 334, y: 217 },
    { x: 349, y: 200 },
    { x: 362, y: 181 },
    { x: 372, y: 161 },
    { x: 380, y: 141 },
    { x: 385, y: 120 },
    { x: 388, y: 100 },
    { x: 389, y: 80 },
    { x: 388, y: 61 },
    { x: 384, y: 43 },
    { x: 382, y: 36 },
    { x: 372, y: 15 },
    { x: 366, y: 8 },
    { x: 360, y: 3 },
    { x: 352, y: 1 },
    { x: 344, y: 0 },
    { x: 336, y: 1 },
    { x: 327, y: 3 },
    { x: 317, y: 7 },
    { x: 304, y: 14 },
    { x: 282, y: 26 },
    { x: 231, y: 55 },
    { x: 187, y: 75 },
    { x: 176, y: 79 },
    { x: 146, y: 86 },
    { x: 121, y: 89 },
    { x: 93, y: 90 },
    { x: 57, y: 88 },
    { x: 19, y: 90 },
    { x: 12, y: 92 },
    { x: 7, y: 95 },
    { x: 3, y: 100 },
    { x: 0, y: 105 },
    { x: 0, y: 113 },
    { x: 0, y: 122 },
    { x: 3, y: 134 },
    { x: 4, y: 139 },
    { x: 15, y: 165 },
    { x: 26, y: 182 },
    { x: 38, y: 199 },
    { x: 54, y: 216 },
    { x: 73, y: 232 },
    { x: 94, y: 246 },
    { x: 117, y: 258 },
    { x: 141, y: 267 },
    { x: 165, y: 274 },
    { x: 188, y: 277 },
    { x: 210, y: 277 },
    { x: 231, y: 274 },
    { x: 251, y: 269 },
  ],
  shape4: [
    { x: 329, y: 197 },
    { x: 328, y: 220 },
    { x: 326, y: 232 },
    { x: 322, y: 242 },
    { x: 319, y: 251 },
    { x: 314, y: 259 },
    { x: 309, y: 267 },
    { x: 303, y: 274 },
    { x: 296, y: 280 },
    { x: 289, y: 286 },
    { x: 281, y: 291 },
    { x: 271, y: 296 },
    { x: 260, y: 301 },
    { x: 247, y: 305 },
    { x: 231, y: 308 },
    { x: 211, y: 311 },
    { x: 184, y: 312 },
    { x: 178, y: 313 },
    { x: 149, y: 311 },
    { x: 127, y: 307 },
    { x: 107, y: 301 },
    { x: 88, y: 293 },
    { x: 71, y: 284 },
    { x: 57, y: 274 },
    { x: 44, y: 263 },
    { x: 33, y: 252 },
    { x: 23, y: 240 },
    { x: 16, y: 228 },
    { x: 10, y: 216 },
    { x: 5, y: 204 },
    { x: 2, y: 192 },
    { x: 0, y: 179 },
    { x: 0, y: 169 },
    { x: 5, y: 145 },
    { x: 12, y: 132 },
    { x: 21, y: 120 },
    { x: 35, y: 106 },
    { x: 56, y: 88 },
    { x: 89, y: 66 },
    { x: 139, y: 41 },
    { x: 203, y: 16 },
    { x: 260, y: 3 },
    { x: 293, y: 0 },
    { x: 296, y: 0 },
    { x: 321, y: 3 },
    { x: 330, y: 7 },
    { x: 337, y: 12 },
    { x: 343, y: 19 },
    { x: 347, y: 27 },
    { x: 349, y: 36 },
    { x: 350, y: 46 },
    { x: 350, y: 57 },
    { x: 349, y: 71 },
    { x: 346, y: 91 },
    { x: 339, y: 129 },
    { x: 330, y: 182 },
    { x: 329, y: 197 },
  ],
  shape5: [
    { x: 415, y: 96 },
    { x: 441, y: 118 },
    { x: 456, y: 137 },
    { x: 469, y: 156 },
    { x: 479, y: 177 },
    { x: 487, y: 200 },
    { x: 492, y: 223 },
    { x: 495, y: 247 },
    { x: 495, y: 273 },
    { x: 491, y: 298 },
    { x: 485, y: 324 },
    { x: 475, y: 350 },
    { x: 462, y: 375 },
    { x: 453, y: 389 },
    { x: 423, y: 419 },
    { x: 402, y: 431 },
    { x: 377, y: 439 },
    { x: 347, y: 445 },
    { x: 309, y: 445 },
    { x: 260, y: 439 },
    { x: 202, y: 423 },
    { x: 141, y: 397 },
    { x: 99, y: 371 },
    { x: 80, y: 354 },
    { x: 71, y: 343 },
    { x: 69, y: 341 },
    { x: 59, y: 321 },
    { x: 55, y: 309 },
    { x: 53, y: 297 },
    { x: 51, y: 284 },
    { x: 50, y: 272 },
    { x: 51, y: 260 },
    { x: 53, y: 247 },
    { x: 56, y: 235 },
    { x: 60, y: 223 },
    { x: 65, y: 211 },
    { x: 71, y: 200 },
    { x: 78, y: 189 },
    { x: 87, y: 179 },
    { x: 96, y: 170 },
    { x: 107, y: 161 },
    { x: 118, y: 153 },
    { x: 120, y: 152 },
    { x: 142, y: 139 },
    { x: 195, y: 109 },
    { x: 251, y: 85 },
    { x: 288, y: 74 },
    { x: 317, y: 70 },
    { x: 341, y: 70 },
    { x: 362, y: 72 },
    { x: 381, y: 78 },
    { x: 398, y: 85 },
    { x: 415, y: 96 },
    { x: 415, y: 96 },
  ],
};
