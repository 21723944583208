import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../utils/theme';
import { EXTERNAL_LINKS, INTERNAL_LINKS } from '../utils/links';
import DubHacksLogo from '../assets/DubHacksLogo.svg';
import FaceBookLogo from '../assets/FBLogo.svg';
import InstagramLogo from '../assets/InstagramLogo.svg';
import LinkedInLogo from '../assets/LinkedInLogo.svg';
import EmailIcon from '../assets/EmailIcon.svg';

const FooterContainer = styled.footer`
  position: static;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0;
  padding-top: 50px;
  padding-bottom: 15px;
  -webkit-box-align: start;
  align-items: flex-start;
  background-color: #ffeef6;
  text-align: center;
  @media (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 479px) {
    display: flex;
    padding-left: 0;
    text-align: left;
  }
`;

const AlignmentContainer = styled.div`
  max-width: 1200px;
  padding-right: 30px;
  padding-left: 30px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    padding-left: 30px;
  }
  @media (max-width: 479px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const FooterFlexContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  padding: 0;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-align: left;
  margin: 0 0 40px;
  @media (max-width: 767px) {
    margin: 0 20px 40px;
    align-items: center;
  }
  @media (max-width: 479px) {
    margin-bottom: 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: justify;
  }
`;

const LeftBlock = styled.div`
  margin-left: 0;
  @media (max-width: 479px) {
    max-width: 125px;
    margin-right: 25px;
    margin-left: 0;
  }
`;

const RightBlock = styled.div`
  margin-top: 29px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: -15px;
  }
`;

const DHLogo = styled.img`
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  @media (max-width: 767px) {
    max-width: 65%;
  }
  @media (max-width: 479px) {
    max-width: 80%;
  }
`;

const TextBlock = styled.div`
  color: ${THEME_COLORS.text};
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 767px) {
    display: none;
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  margin-top: 50px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 10px;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    padding-right: 0;
  }
`;

const SocialLinkInlineBlock = styled.a`
  margin-right: 20px;
  max-width: 100%;
  display: inline-block;
  background-color: transparent;
`;

const SocialLinkSVG = styled.img`
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  color: ${THEME_COLORS.text};
`;

const RightList = styled.ul`
  font-weight: 500;
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 10px;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;
const RightListItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 15px;
  @media (max-width: 479px) {
    margin-bottom: 10px;
  }
`;

const RightListLink = styled.a`
  color: ${THEME_COLORS.text};
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 479px) {
    font-size: 11px;
  }
`;
const BottomTagLine = styled.div`
  color: ${THEME_COLORS.text};
  font-size: 15px;
  font-weight: 500;
  @media (max-width: 479px) {
    padding-left: 0;
    font-size: 9px;
    text-align: center;
  }
`;
export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <AlignmentContainer className="something">
        <FooterFlexContainer>
          <LeftBlock>
            <DHLogo src={DubHacksLogo} loading="lazy" alt="DubHacks Logo" />
            <TextBlock>building tomorrow together</TextBlock>
            <SocialLinksContainer>
              <SocialLinkInlineBlock href={EXTERNAL_LINKS.DH_FB} target="_blank">
                <SocialLinkSVG src={FaceBookLogo} loading="lazy" />
              </SocialLinkInlineBlock>
              <SocialLinkInlineBlock href={EXTERNAL_LINKS.DH_LINKEDIN} target="_blank">
                <SocialLinkSVG src={LinkedInLogo} loading="lazy" />
              </SocialLinkInlineBlock>
              <SocialLinkInlineBlock href={EXTERNAL_LINKS.DH_INSTAGRAM} target="_blank">
                <SocialLinkSVG src={InstagramLogo} loading="lazy" />
              </SocialLinkInlineBlock>
              <SocialLinkInlineBlock href={EXTERNAL_LINKS.DH_EMAIL} target="_blank">
                <SocialLinkSVG src={EmailIcon} loading="lazy" />
              </SocialLinkInlineBlock>
            </SocialLinksContainer>
          </LeftBlock>
          <RightBlock>
            <RightList>
              <RightListItem>
                <RightListLink href={INTERNAL_LINKS.JOIN}>Join our mailing list</RightListLink>
              </RightListItem>
              <RightListItem>
                <RightListLink href={EXTERNAL_LINKS.DH_STATS} target="_blank">
                  Stats
                </RightListLink>
              </RightListItem>
              <RightListItem>
                <RightListLink href={EXTERNAL_LINKS.MLH_CODE_OF_CONDUCT} target="_blank">
                  Code of Conduct
                </RightListLink>
              </RightListItem>
            </RightList>
          </RightBlock>
        </FooterFlexContainer>
        <BottomTagLine>Made with &lt;3, by the DubHacks Team.</BottomTagLine>
      </AlignmentContainer>
    </FooterContainer>
  );
};
