import React from 'react';
import { AboutDH } from '../components/AboutDH';
import { Mission } from '../components/Mission';
import { OurTeam } from '../components/OurTeam';

const PageAboutBase: React.FC = props => {
  return (
    <>
      <AboutDH />
      <Mission />
      <OurTeam />
    </>
  );
};

export default PageAboutBase;
