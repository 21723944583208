import React from 'react';
import { Hero } from '../components/Hero';
import { Orgs } from '../components/Orgs';
import { HeroEngine } from '../components/HeroEngine';
import { Banner } from '../components/Banner';

const PageHomeBase: React.FC = props => {
  return (
    <>
      <Banner />
      <HeroEngine />
      <Hero />
      <Orgs />
    </>
  );
};
export default PageHomeBase;
