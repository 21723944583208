import React, { useEffect } from 'react';
import Matter from 'matter-js';
import { bodies } from './Bodies';
import styled from 'styled-components';
import shape1 from '../assets/shape1.svg';
import shape2 from '../assets/shape2.svg'
import shape3 from '../assets/shape3.svg';
import shape4 from '../assets/shape4.svg';
import shape5 from '../assets/shape5.svg';
import MatterWrap from 'matter-wrap'

const CanvasElement = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`;
const Shape = styled.img`
  position: absolute;
  vertical-align: middle;
  z-index: -1;
`;

export const HeroEngine = () => {
  useEffect(() => {
    const Engine = Matter.Engine;
    const Render = Matter.Render;
    const Runner = Matter.Runner;
    const MouseConstraint = Matter.MouseConstraint;
    const Mouse = Matter.Mouse;
    const Composite = Matter.Composite;
    const Bodies = Matter.Bodies;
    const Common = Matter.Common;

    Common.setDecomp(require('poly-decomp'));
    Matter.use(MatterWrap);

    // create engine
    var engine = Engine.create(),
      world = engine.world;

    engine.gravity.scale = 0.000002;

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    console.log(document.documentElement.clientHeight)
    // create renderer
    var render = Render.create({
      element: document.getElementById('canvas-element') || undefined,
      engine: engine,
      options: {
        width: vw,
        height: vh,
        background: '#ffffff00',
      },
    });

    render.options.background = '#ffffff00';
    render.options.wireframes = false;

    Render.run(render);

    // create runner
    var runner = Runner.create();
    Runner.run(runner, engine);

    // add bodies
    /*Composite.add(world, [
  // walls
  Bodies.rectangle(10, vh/2, 10, vh, { isStatic: true }),
  Bodies.rectangle(vw, vh/2, 10, vh, { isStatic: true }),
  Bodies.rectangle(vw/2, 10, vw, 10, { isStatic: true }),
  Bodies.rectangle(vw/2, vh, vw, 10, { isStatic: true })
]);*/

    let compound_bodies = {};
    let centers = {};
    for (let key in bodies) {
      console.log(key, bodies[key]);
      const x = 200 + Math.random() * 1000;
      const y = 200 + Math.random() * 500;
      compound_bodies[key] = Bodies.fromVertices(x, y, bodies[key], { render: { fillStyle: '#0000' } });
      centers[key] = Matter.Vertices.centre(bodies[key]);
      console.log(compound_bodies);

      // set body to random angular velocity
      Matter.Body.setAngularVelocity(compound_bodies[key], Math.random() / 5 - 0.1);

      console.log('setting angular velocity');

      let angle = Math.random() * 6.28;
      let vx = Math.cos(angle);
      let vy = Math.sin(angle);
      let speed = Math.random() * 3;
      Matter.Body.setVelocity(compound_bodies[key], { x: vx * speed, y: vy * speed });

      compound_bodies[key].plugin.wrap = {
        min: {
          x: 0,
          y: 0,
        },
        max: {
          // x: render.canvas.width,
          // y: render.canvas.height,
          x : vw,
          y: vh
        },
      };

      compound_bodies[key].friction = 0.3;
      compound_bodies[key].frictionAir = 0.001;
      Composite.add(world, compound_bodies[key]);

      // create a new image tag to track the composite
      /*let element  = document.createElement("img");
  element.className = "physics-object"
  element.src = './' + key + '.svg';
  element.id = key;
  document.body.appendChild(element);*/
    }

    // only add mouse control if width is greater than 600 pixels
    // to eliminate mobile devices
    if (window.innerWidth > 600) {
      // add mouse control
      var mouse = Mouse.create(render.canvas),
        mouseConstraint = MouseConstraint.create(engine, {
          mouse: mouse,
          constraint: {
            stiffness: 0.2,
            render: {
              visible: false,
            },
          },
        });
      mouse.element.removeEventListener('mousewheel', mouse.mousewheel);
      mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel);
      Mouse.setScale(mouse, { x: 0.8, y: 0.8 });
      // keep the mouse in sync with rendering
      render.mouse = mouse;
      Composite.add(world, mouseConstraint);
    }

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: vw, y: vh },
    });

    // console.log(compound_bodies);

    Matter.Events.on(runner, 'afterTick', () => {
      for (let key in bodies) {
        // this is probably inefficient, should prefetch these and store them
        // in array
        let obj = document.getElementById(key);
        let compound = compound_bodies[key];
        let center = centers[key];
        
        if (obj) {
          obj.style.transform = `rotate(${(compound.angle * 180) / 3.1415}deg)`;
          obj.style['transform-origin'] = `${center.x}px ${center.y}px `;
          obj.style['top'] = compound.position.y - center.y + 'px';
          obj.style['left'] = compound.position.x - center.x + 'px';
        }
      }
    });

    window.addEventListener('resize', () => {
      let vw = window.innerWidth;
      let vh = window.innerHeight;

      render.bounds.max.x = vw;
      render.bounds.max.y = vh;

      render.options.width = vw;
      render.options.height = vh;

      render.canvas.width = vw;
      render.canvas.height = vh;

      for (let key in bodies) {
        let compound = compound_bodies[key];
        compound.plugin.wrap = {
          min: {
            x: 0,
            y: 0,
          },
          max: {
            x: vw,
            y: vh,
          },
        };
      }

      console.log('resizing to', window.innerWidth, window.innerHeight);
    });

    Matter.Runner.run(engine);
    Render.run(render);
  });
  return (
    <CanvasElement id="canvas-element">
      <Shape src={shape1} id="shape1" loading="lazy" />
      <Shape src={shape2} id="shape2" loading="lazy" />
      <Shape src={shape3} id="shape3" loading="lazy" />
      <Shape src={shape4} id="shape4" loading="lazy" />
      <Shape src={shape5} id="shape5" loading="lazy" />
    </CanvasElement>
  );
};
