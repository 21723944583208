import React from 'react';
import styled from 'styled-components';

const MissionSection = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 0;
  @media (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const MissionContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  @media (max-width: 479px) {
    max-width: none;
  }
`;

const MissionTitle = styled.div`
  margin-bottom: 30px;
  padding: 10px;
  font-family: 'Work Sans', sans-serif;
  color: #434343;
  font-size: 35px;
  line-height: 35px;
  font-weight: 700;
  text-align: center;
`;

const MissionGrid = styled.div`
  display: grid;
  justify-content: stretch;
  justify-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 142px;
  grid-template-areas: 'Area';
  -ms-grid-columns: 1fr 16px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 142px auto;
  grid-template-rows: auto auto;
  justify-content: stretch;
  justify-items: center;
  @media (max-width: 767px) {
    grid-row-gap: 35px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  @media (max-width: 479px) {
    grid-row-gap: 35px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
`;

const FirstGridCell = styled.div`
  grid-row-start: Area;
  grid-column-start: Area;
  grid-row-end: Area;
  grid-column-end: Area;
  grid-area: Area;
`;

const MissionGridCell = styled.div`
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
`;

const CellTitle = styled.div`
  margin-bottom: 10px;
  font-family: 'Work Sans', sans-serif;
  color: #f63690;
  font-size: 100px;
  line-height: 100px;
  font-weight: 700;
  text-align: center;
  @media (max-width: 479px) {
    text-align: center;
  }
`;

const CellBody = styled.div`
  padding: 10px 40px 10px 50px;
  font-family: 'Work Sans', sans-serif;
  color: #434343;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 479px) {
    text-align: center;
  }
`;

export const Mission = () => {
  return (
    <MissionSection>
      <MissionContainer>
        <MissionTitle>Our Mission</MissionTitle>
        <MissionGrid>
          <FirstGridCell>
            <CellTitle>01</CellTitle>
            <CellBody>Challenge students to create technology responsibly</CellBody>
          </FirstGridCell>
          <MissionGridCell>
            <CellTitle>02</CellTitle>
            <CellBody>
              Engage and center people who are underrepresented in the industry to advance equity in tech
            </CellBody>
          </MissionGridCell>
          <MissionGridCell>
            <CellTitle>03</CellTitle>
            <CellBody>Connect students to resources that support their development as technologists</CellBody>
          </MissionGridCell>
          <MissionGridCell>
            <CellTitle>04</CellTitle>
            <CellBody>Foster a welcoming and vibrant community for students to experiment and grow together</CellBody>
          </MissionGridCell>
        </MissionGrid>
      </MissionContainer>
    </MissionSection>
  );
};
