import React from 'react';
import styled from 'styled-components';
import { DHSlidePuzzle } from './DHSlidePuzzle';

const AboutSection = styled.div`
  width: 100%;
  height: 720px;
  @media (max-width: 767px) {
    height: auto;
  }
`;

const PuzzleAboutContainer = styled.div`
  max-width: 1200px;
  display: flex;
  padding: 80px 20px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 767px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
`;

const PuzzleContainer = styled.div`
  display: flex;
  margin-right: 8%;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 50%;
  @media (max-width: 767px) {
    margin-right: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
`;

const AboutContainer = styled.div`
  margin-left: 8%;
  flex: 50%;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const TextBlock = styled.div`
  display: block;
  margin-left: 0;
  color: #434343;
  font-size: 32px;
  line-height: 44px;
  font-weight: 500;
  @media (max-width: 991px) {
    padding: 15px 10px;
    font-size: 25px;
  }
  @media (max-width: 767px) {
    display: block;
    margin-top: 40px;
    margin-left: auto;
    width: 85%;
    margin-right: auto;
  }
`;

const Tomorrow = styled(TextBlock)`
  color: #f63690;
  font-weight: 600;
`;

export const AboutDH = () => {
  return (
    <>
      <AboutSection>
        <PuzzleAboutContainer>
          <PuzzleContainer>
            <DHSlidePuzzle />
          </PuzzleContainer>
          <AboutContainer>
            <TextBlock>
              DubHacks is a tech and entrepreneurship non-profit in Seattle run by students at the University of
              Washington.
              <br />
              <br />
              We bring together students of all backgrounds to tackle the issues they are most passionate about.
              <br />
              <br />
            </TextBlock>
            <Tomorrow>Let's build tomorrow together.</Tomorrow>
          </AboutContainer>
        </PuzzleAboutContainer>
      </AboutSection>
    </>
  );
};
