import styled from 'styled-components';
import { THEME_COLORS } from '../utils/theme';
import { EXTERNAL_LINKS } from '../utils/links';

const FormSection = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  max-width: 800px;
  width: 100vw;
  margin: 20px auto;
  height: fit-content;

  @media (max-width: 991px) {
    margin-right: 10%;
    margin-left: 10%;
  }

  @media (max-width: 767px) {
    margin-right: 5%;
    margin-left: 5%;
  }
`;

const FormIframe = styled.iframe`
  background: transparent;
  border: 1px solid #ccc;
  width: 100%;
  height: 75vh;
`;

const Description = styled.div`
  line-height: 20px;
  max-width: 479px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  color: ${THEME_COLORS.brandPink};
  font-size: 18px;
  text-align: center;
  object-fit: fill;
  align-self: start;
  font-weight: 500;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const GoogleForm = () => {
  return (
    <FormSection>
      <Description>
        Not hacking?&nbsp;
        <a target="_blank" rel="noreferrer noopener" href={EXTERNAL_LINKS.DH_24_MENTOR_SIGN_UP}>
          Apply to be a Judge/Mentor
        </a>
      </Description>
      <FormWrapper>
        <FormIframe src={EXTERNAL_LINKS.DH_24_APPLY} frameBorder="0"></FormIframe>
      </FormWrapper>
    </FormSection>
  );
};
