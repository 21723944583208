import React, { useEffect } from 'react';

export const DHSlidePuzzle = () => {
  useEffect(() => {
    const COLORS = {
      pink: '#FF37A0',
      blue: '#61CDFF',
      yellow: '#FFCE53',
    };
    const cellWidth = 112;
    const cellHeight = 135;

    const letters = [
      { letter: 'D', color: COLORS.pink, x: 0, y: 0 },
      { letter: 'U', color: COLORS.blue, x: 1, y: 0 },
      { letter: 'B', color: COLORS.yellow, x: 2, y: 0 },
      { letter: 'H', color: COLORS.yellow, x: 0, y: 1 },
      { letter: 'A', color: COLORS.pink, x: 1, y: 1 },
      { letter: 'C', color: COLORS.blue, x: 2, y: 1 },
      { letter: 'K', color: COLORS.blue, x: 0, y: 2 },
      { letter: 'S', color: COLORS.yellow, x: 1, y: 2 },
    ];
    const empty = { x: 2, y: 2 };

    const container = document.getElementById('dh-slide-puzzle');
    container.style.width = `${cellWidth * 3}px`;
    container.style.height = `${cellHeight * 3}px`;

    const mountLetters = () => {
      letters.forEach(letter => {
        const letterElt = document.createElement('span');
        letterElt.className = 'dh-slide-letter';
        letterElt.style.color = letter.color;
        letterElt.innerHTML = letter.letter;
        letter.elt = letterElt;
        //
        commitTransform(letter);
        container.appendChild(letterElt);
      });
    };

    const commitTransform = letter => {
      if (letter.elt) {
        letter.elt.style.transform = `translate(${letter.x * cellWidth}px, ${letter.y * cellHeight}px)`;
      }
    };

    const swap = (a, b) => {
      [a.x, a.y, b.x, b.y] = [b.x, b.y, a.x, a.y];
      commitTransform(a);
      commitTransform(b);
    };

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const neighbors = letter => {
      const neighbors = [];
      if (letter.x > 0) {
        neighbors.push({ x: letter.x - 1, y: letter.y });
      }
      if (letter.x < 2) {
        neighbors.push({ x: letter.x + 1, y: letter.y });
      }
      if (letter.y > 0) {
        neighbors.push({ x: letter.x, y: letter.y - 1 });
      }
      if (letter.y < 2) {
        neighbors.push({ x: letter.x, y: letter.y + 1 });
      }
      return neighbors;
    };

    const findLetter = coords => {
      return letters.find(letter => letter.x === coords.x && letter.y === coords.y);
    };

    (async () => {
      const history = [];

      // Roll randomly backwards 30 steps to create a "scrambled" state.
      while (history.length < 30) {
        let n = neighbors(empty);
        n = n.filter(coord => history[0] === undefined || !(coord.x === history[0].x && coord.y === history[0].y));
        //
        const target = findLetter(n[Math.floor(Math.random() * n.length)]);
        history.unshift({ ...empty });
        swap(empty, target);
      }

      // Mount the state as it currently exists onto the page.
      mountLetters();

      // Unroll the history, this time visibly, and end in the unscrambled state.
      while (history.length > 0) {
        await delay(120);
        swap(empty, findLetter(history.shift()));
      }
    })().catch(console.error);
  });

  return <div id="dh-slide-puzzle"></div>;
};
